import cf = egr.wcf.cf;
import ProgressUI from '../progress';
import './index.css';

/**
 * Used for saving and loading a project.
 */
class PersistenceUI {
    constructor(pArticleManager: cf.ArticleManager, pOnLoad: (pFile: Blob, pFileType: 'obk' | 'pec') => Promise<void>) {
        const tContainer: HTMLDivElement = document.getElementById('persistence') as HTMLDivElement;

        const tSave: HTMLButtonElement = document.createElement('button');
        tSave.className = 'save';
        tSave.innerText = 'Save .obk';
        tSave.onclick = this.onSaveClicked.bind(this, pArticleManager);
        tContainer.appendChild(tSave);

        const tLoad: HTMLInputElement = document.createElement('input');
        tLoad.className = 'load';
        tLoad.type = 'file';
        tLoad.accept = '.obk,.pec';
        tLoad.onchange = this.onLoadClicked.bind(this, pOnLoad);
        tContainer.appendChild(tLoad);
    }

    private async onLoadClicked(pOnLoad: (pFile: Blob, pFileType: 'obk' | 'pec') => Promise<void>, pEvent: Event): Promise<void> {
        if (pEvent.target instanceof HTMLInputElement && pEvent.target.files != null && pEvent.target.files.length > 0) {
            const tFileExtension: string = pEvent.target.files[0].name.substr(pEvent.target.files[0].name.length - 3).toLocaleLowerCase();
            if (tFileExtension === 'obk' || tFileExtension === 'pec') {
                await pOnLoad(pEvent.target.files[0], tFileExtension);
            } else {
                alert('no .obk/.pec file given');
            }
        }
    }

    private async onSaveClicked(pArticleManager: cf.ArticleManager): Promise<void> {
        ProgressUI.beginLoading();
        await pArticleManager.synchronizeSession(true); // so the server has all new data and we can save it in the next line
        const tPathToSavedScene: string = await pArticleManager.session.session.saveSession(null);
        ProgressUI.endLoading();
        window.location.href = tPathToSavedScene;
    }
}
export default PersistenceUI;