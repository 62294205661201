import core = egr.wcf.core;
import utils = egr.wcf.utils;
import ProgressUI from '../progress';
import './index.css';
/**
 * This class handles OAP (OFML aided planning).
 * If an OAP-Icon is clicked by the user, a popup at this location will be created.
 * To see this class in action, the OAP-feature must be present in the OFML-data.
 * @see https://www.easterngraphics.com/en/newsletter/2018-01-en.html#c13090
 */
class OapUI {
    constructor(pPropertyProvider: core.prop.MultiPropertyProvider, pAppCallbacks: egr.wcf.core.AppCallbacks) {
        pPropertyProvider.eventPropertiesChanged.addListener(this.onPropertiesChanged.bind(this));

        // setup core oap callback functions
        pAppCallbacks.showPropertyEditor = this.showPropertyEditorPopup.bind(this);
        pAppCallbacks.showActionChoice = this.showActionsPopup.bind(this);
        pAppCallbacks.beginAsyncOperation = () => ProgressUI.beginLoading();
        pAppCallbacks.endAsyncOperation = () => ProgressUI.endLoading();
        pAppCallbacks.showMedia = this.showMediaPopup.bind(this);
    }

    /**
     * We remove the popup if the properties changed. Because the values in popup might not be valid anymore.
     */
    private onPropertiesChanged(pResult?: core.prop.PropertyChangedResult): void {
        if (pResult !== core.prop.PropertyChangedResult.Nothing) {
            this.removeOapPopup();
        }
    }

    private removeOapPopup(): void {
        const tContainer: HTMLElement | null = document.getElementById('oap');
        if (tContainer != null) {
            document.body.removeChild(tContainer);
        }
    }

    /**
     * Shows the property choices of an oap property at the position of the oap configure icon.
     * It like a tiny property editor at the icon position.
     */
    private async showPropertyEditorPopup(
        pSceneElement: core.mdl.SceneElement,
        getPropertiesCallback: () => Promise<Array<core.prop.Property>>,
        pOptions: core.AppCallbacks.ShowPropertyEditorOptions
    ): Promise<void> {
        this.removeOapPopup();
        const tContainer: HTMLDivElement = document.createElement('div');
        tContainer.id = 'oap';
        tContainer.className = 'oap-property-editor';
        if (pOptions.originReference != null) {
            tContainer.style.top = pOptions.originReference.top.toString() + 'px';
            tContainer.style.left = pOptions.originReference.left.toString() + 'px';
        }
        if (pOptions.title != null) {
            const tTitle: HTMLDivElement = document.createElement('div');
            tTitle.innerText = pOptions.title;
            tTitle.className = 'title';
            tContainer.appendChild(tTitle);
        }
        document.body.appendChild(tContainer);

        const tProperties: Array<core.prop.Property> = await getPropertiesCallback();
        if (tProperties.length === 1 && tProperties[0].choiceList) { // if we have only one property, we show the property choices directly
            const tChoices: Array<core.prop.PropertyValue> | null = await tProperties[0].getChoices();
            if (tChoices != null) {
                tChoices.forEach((pChoice) => this.createPropertyChoice(tProperties[0], pChoice, tContainer));
            }
        } else {
            for (const tProperty of tProperties) {
                this.createProperty(tProperty, tContainer);
            }
        }
    }

    private createProperty(pProperty: core.prop.Property, pParentContainer: HTMLDivElement): void {
        const tProperty: HTMLDivElement = document.createElement('div');
        const tPropertyValue: core.prop.PropertyValue | null = pProperty.getValue();
        tProperty.innerText = pProperty.getName() + ': ' + (tPropertyValue != null ? tPropertyValue.text : '');
        tProperty.className = 'property';
        pParentContainer.appendChild(tProperty);
        if (pProperty.editable && pProperty.visible) {
            tProperty.onclick = this.onPropertyClick.bind(this, pProperty, pParentContainer);
        }
        // save information in dataset css
        tProperty.dataset.editable = pProperty.editable ? 'true' : 'false';
        tProperty.dataset.visible = pProperty.visible ? 'true' : 'false';
        tProperty.dataset.choiceList = pProperty.choiceList ? 'true' : 'false';
    }

    private async onPropertyClick(pProperty: core.prop.Property, pParentContainer: HTMLDivElement, pMouseEvent: MouseEvent): Promise<void> {
        if (pProperty.choiceList) {
            ProgressUI.beginLoading();
            this.removeAllProperties();
            const tPropertyChoices: Array<core.prop.PropertyValue> | null = await pProperty.getChoices();
            if (tPropertyChoices != null) {
                tPropertyChoices.forEach((pPropertyValue: core.prop.PropertyValue) =>
                    this.createPropertyChoice(pProperty, pPropertyValue, pParentContainer)
                );
            }
            ProgressUI.endLoading();
        } else {
            const tUserInput: string | null = prompt(pProperty.getName());
            if (tUserInput != null) {
                await pProperty.setValue(tUserInput);
            }
        }
    }

    private removeAllProperties(): void {
        const tContainer: HTMLElement | null = document.getElementById('oap');
        if (tContainer != null) {
            const tProperties: HTMLCollectionOf<Element> = tContainer.getElementsByClassName('property');
            let tProperty: Element | null = tProperties.item(0);
            while (tProperty != null) {
                tProperty.remove();
                tProperty = tProperties.item(0);
            }
        }
    }

    private createPropertyChoice(
        pProperty: core.prop.Property,
        pPropertyValue: core.prop.PropertyValue,
        pParentContainer: HTMLDivElement
    ): void {
        const tPropertyChoice: HTMLDivElement = document.createElement('div');
        const tPropertyValue: core.prop.PropertyValue | null = pProperty.getValue();
        tPropertyChoice.className = 'property-choice' + ((tPropertyValue != null && tPropertyValue.value === pPropertyValue.value) ? ' selected' : '');
        tPropertyChoice.onclick = async () => {
            ProgressUI.beginLoading();
            await pProperty.setValue(pPropertyValue.value);
            ProgressUI.endLoading();
        };
        pParentContainer.appendChild(tPropertyChoice);
        if (!utils.string.isNullOrEmpty(pPropertyValue.largeIcon)) {
            const tIcon: HTMLImageElement = document.createElement('img');
            tIcon.src = pPropertyValue.largeIcon;
            tPropertyChoice.appendChild(tIcon);
        }
        if (!utils.string.isNullOrEmpty(pPropertyValue.text)) {
            const tLabel: HTMLDivElement = document.createElement('div');
            tLabel.innerText = pPropertyValue.text;
            tPropertyChoice.appendChild(tLabel);
        }
    }

    /**
     * Shows a popup for the oap actions at the position of the oap action icon.
     */
    private async showActionsPopup(
        pActions: Array<core.AppCallbacks.ChoiceAction>,
        pOptions: core.AppCallbacks.ShowActionChoiceOptions
    ): Promise<void> {
        this.removeOapPopup();
        const tContainer: HTMLDivElement = document.createElement('div');
        tContainer.id = 'oap';
        tContainer.className = 'oap-action-editor ' + pOptions.displayMode; // tile or list view
        if (pOptions.originReference != null) {
            tContainer.style.top = pOptions.originReference.top.toString() + 'px';
            tContainer.style.left = pOptions.originReference.left.toString() + 'px';
        }
        if (pOptions.title != null) {
            tContainer.innerText = pOptions.title;
        }
        document.body.appendChild(tContainer);
        pActions.forEach((pAction) => this.createActionChoice(pAction, tContainer, pOptions.tileSize));
    }

    /**
     * Creates an html element for an action choice.
     */
    private createActionChoice(
        pAction: core.AppCallbacks.ChoiceAction,
        pParentPropertyItem: HTMLDivElement,
        pTileSize: 'small' | 'medium' | 'large' | undefined
    ): void {
        const tActionChoice: HTMLDivElement = document.createElement('div');
        tActionChoice.className = 'oap-action' + (pTileSize !== undefined ? ' ' + pTileSize : '');
        tActionChoice.onclick = async () => {
            await pAction.execute();
        };
        if (pAction.image != null) {
            pParentPropertyItem.appendChild(tActionChoice);
            const tIcon: HTMLImageElement = document.createElement('img');
            tIcon.className = 'oap-action-icon';
            tIcon.src = pAction.image;
            tActionChoice.appendChild(tIcon);
        }
        if (pAction.text != null) {
            const tLabel: HTMLDivElement = document.createElement('div');
            tLabel.innerText = pAction.text;
            tLabel.className = 'oap-action-label';
            tActionChoice.appendChild(tLabel);
        }
    }
    /**
 * Shows a popup for the oap actions at the position of the oap action icon.
 */
    private async showMediaPopup(
        pMedia: core.AppCallbacks.Media,
        pOptions: core.AppCallbacks.ShowMediaOptions
    ): Promise<void> {
        this.removeOapPopup();
        const tContainer: HTMLDivElement = document.createElement('div');
        tContainer.id = 'oap';
        tContainer.className = 'oap-media';
        if (pOptions.originReference != null) {
            tContainer.style.top = pOptions.originReference.top.toString() + 'px';
            tContainer.style.left = pOptions.originReference.left.toString() + 'px';
        }
        document.body.appendChild(tContainer);
        if (pMedia.type === 'YouTube') {
            const tYouTube: HTMLIFrameElement = document.createElement('iframe');
            tYouTube.width = '560px';
            tYouTube.height = '315px';
            tYouTube.allowFullscreen = true;
            tYouTube.frameBorder = '0';
            tYouTube.src = `https://www.youtube.com/embed/${pMedia.id}?&autoplay=1`;
            tContainer.appendChild(tYouTube);

            // add icon to close iFrame
            const tCloseIcon: HTMLDivElement = document.createElement('div');
            tCloseIcon.innerText = 'X';
            tCloseIcon.onclick = () => this.removeOapPopup();
            tCloseIcon.className = 'close';
            tContainer.appendChild(tCloseIcon);
        }
    }
}
export default OapUI;