import cf = egr.wcf.cf;
import basket = egr.wcf.eaiws.basket;
import ProgressUI from '../progress';
import './index.css';
/**
 * Shows commercial information about the current articles in the scene, like article number, price, sub articles etc.
 */
class BasketUI {
    private mArticleManager: cf.ArticleManager;
    private mOnItemClicked: (pItem: cf.ArticleElement, pEvent: MouseEvent) => void; // callback if user clicks on a basket item
    constructor(pArticleManager: cf.ArticleManager, pOnItemClicked: (pItem: cf.ArticleElement, pEvent: MouseEvent) => void) {
        this.mArticleManager = pArticleManager;
        this.mOnItemClicked = pOnItemClicked;
        pArticleManager.eventArticleChanged.addListener(this.updateBasket.bind(this)); // if a property of an article was changed, we need to update the basket
    }

    /**
     * Gets articles items from basket service and displays them.
     */
    public async updateBasket(): Promise<void> {
        const tContainer: HTMLDivElement = document.getElementById('basket') as HTMLDivElement;
        while (tContainer.lastChild) { // remove all html elements, so we start from blank
            tContainer.removeChild(tContainer.lastChild);
        }
        // create new basket html elements
        const tMainArticles: Array<cf.MainArticleElement> = this.mArticleManager.getAllMainArticles();
        for (const tMainArticle of tMainArticles) {
            await this.createBasketItem(tMainArticle, tContainer);
        }
    }

    /**
     * Creates a html element for a basket article and its sub articles.
     */
    private async createBasketItem(pArticle: cf.ArticleElement, pParentContainer: HTMLDivElement): Promise<void> {
        const tItem: HTMLDivElement = document.createElement('div');
        tItem.onclick = this.mOnItemClicked.bind(this, pArticle);
        tItem.className = 'basket-item';
        const tItemProperties: basket.ItemProperties = await pArticle.getItemProperties();
        if (tItemProperties.article != null) {
            tItem.innerHTML = `
                <div class="manufacturer">${tItemProperties.article.manufacturerId}</div>
                <div class="series">${tItemProperties.article.seriesId}</div>
                <div class="baseArticleNumber">${tItemProperties.article.baseArticleNumber}</div>
                <div class="short-text">${tItemProperties.article.shortText}</div>
                <div class="long-text">${tItemProperties.article.longText}</div>
                <div class="feature-text">${tItemProperties.article.featureText}</div>
                <div class="price">${tItemProperties.article.salesPrice} ${tItemProperties.article.salesCurrency}</div>
            `;
        }
        pParentContainer.appendChild(tItem);
        // sub articles
        const tSubArticles: Array<cf.SubArticleElement> = pArticle.getSubArticles(false);
        if (tSubArticles.length > 0) {
            const tSubItems: HTMLDivElement = document.createElement('div');
            tSubItems.className = 'basket-sub-items';
            tItem.appendChild(tSubItems);
            for (const tSubArticle of tSubArticles) {
                await this.createBasketItem(tSubArticle, tSubItems);
            }
        }

        // handle migration of older articles (.obk, .pec)
        if (pArticle instanceof cf.MainArticleElement) {
            const tOfmlState: basket.OFMLUpdateState = await pArticle.getOfmlUpdateState();
            tItem.dataset.updateState = tOfmlState;
            if (tOfmlState === 'Migratable' || tOfmlState === 'Updatable') {
                const tMigrationButton: HTMLButtonElement = document.createElement('button');
                tMigrationButton.innerText = tOfmlState === 'Migratable' ? 'Migrate article' : 'Update article';
                tMigrationButton.onclick = async () => {
                    ProgressUI.beginLoading();
                    await pArticle.updateOfmlArticle(true, true);
                    ProgressUI.endLoading();
                };
                tItem.appendChild(tMigrationButton);
            }
        }
    }
}
export default BasketUI;