import cf = egr.wcf.cf;
import core = egr.wcf.core;
import utils = egr.wcf.utils;
import eaiws = egr.wcf.eaiws;
import ProgressUI from '../progress';
import './index.css';
/**
 * Creates export data like screenshots or 3D files (DWG, 3DS,..).
 */
class ExportUI {
    constructor(pArticleManager: cf.ArticleManager, pViewer: core.view.Viewer) {
        const tContainer: HTMLDivElement = document.getElementById('export') as HTMLDivElement;
        this.createDownloadScreenshot(pViewer, tContainer);
        this.createDownloadGeometry(pArticleManager, '3DS', tContainer);
        this.createDownloadGeometry(pArticleManager, 'DWG', tContainer);
        this.createDownloadGeometry(pArticleManager, 'DXF', tContainer);

        // this.createDownloadGeometry(pArticleManager, 'SKP', tContainer); // works only on windows eaiws servers
    }

    private createDownloadScreenshot(pViewer: core.view.Viewer, pParentContainer: HTMLDivElement): void {
        const tButton: HTMLButtonElement = document.createElement('button');
        tButton.className = 'export-item';
        tButton.innerText = 'Screenshot';
        tButton.onclick = this.onScreenshotClicked.bind(this, pViewer);
        pParentContainer.appendChild(tButton);
    }

    private async onScreenshotClicked(pViewer: core.view.Viewer): Promise<void> {
        ProgressUI.beginLoading();
        const tBlob: Blob = await pViewer.createScreenshot({ width: 800, height: 600, mimeType: 'image/jpeg' }, true);
        // create download
        if (navigator.msSaveBlob != null) {
            // IE
            navigator.msSaveBlob(tBlob, 'screenshot.jpg');
        } else {
            const tLink: HTMLAnchorElement = document.createElement('a');
            tLink.style.display = 'none';
            document.body.appendChild(tLink);

            const tUrl: string = URL.createObjectURL(tBlob);
            tLink.href = tUrl;
            tLink.download = 'screenshot.jpg';
            tLink.click();

            URL.revokeObjectURL(tUrl);
            document.body.removeChild(tLink);
        }
        ProgressUI.endLoading();
    }

    private createDownloadGeometry(pArticleManager: cf.ArticleManager, pFormat: '3DS' | 'DWG' | 'DXF' | 'SKP', pParentContainer: HTMLDivElement): void {
        const tButton: HTMLButtonElement = document.createElement('button');
        tButton.className = 'export-item';
        tButton.innerText = pFormat;
        tButton.onclick = this.onDownloadClicked.bind(this, pArticleManager, pFormat);
        pParentContainer.appendChild(tButton);
    }

    private async onDownloadClicked(pArticleManager: cf.ArticleManager, pFormat: '3DS' | 'DWG' | 'DXF' | 'SKP'): Promise<void> {
        ProgressUI.beginLoading();
        let tExportOptions: Array<string> | null = null;
        switch (pFormat) {
            case 'DWG':
                tExportOptions = [
                    'format=DWG',
                    'hideSubArticles=false',
                    'no2D=true',
                    'textures=true',
                    'materials=true'
                ];
                break;

            case 'DXF':
                tExportOptions = [
                    'format=DWG',
                    'dxf=true',
                    'hideSubArticles=false',
                    'no2D=true',
                    'textures=true',
                    'materials=true'
                ];
                break;

            case '3DS':
                tExportOptions = [
                    'format=3DS',
                    'hideSubArticles=false',
                    'textures=true'
                ];
                break;

            case 'SKP':
                tExportOptions = [
                    'format=SKP',
                    'hideSubArticles=false',
                    'no2D=true',
                    'textures=false',
                    'textureToColor=true'
                ];
                break;
            default:
                break;
        }
        if (tExportOptions == null) {
            return;
        }

        try {
            const tArticles: Array<cf.MainArticleElement> = pArticleManager.getAllMainArticles();

            // export obx
            const tObxUrl: string = await pArticleManager.exportObx(tArticles);

            // create temporary set article
            const tFolderId: string = await pArticleManager.session.basket.insertFolder(null, null, 'Set');
            const tSetId: string = await pArticleManager.session.basket.convertToSetArticle(tFolderId);

            // insert obx into set article
            const tPastedIds: Array<string> = await pArticleManager.session.basket.paste(tSetId, null, tObxUrl);

            // add to set article
            if (tPastedIds != null) {
                await pArticleManager.session.basket.addToSetArticle(tPastedIds);

                // export geometry
                const tExportUrl: string = await pArticleManager.session.basket.getExportedGeometry(tSetId, tExportOptions);

                // delete temporary set
                const tOptions: eaiws.basket.DeleteItemsOptions = new eaiws.basket.DeleteItemsOptions();
                tOptions.subItems = true;
                await pArticleManager.session.basket.deleteItems([tSetId], tOptions);

                // delete temporary obx
                await pArticleManager.session.deleteFile(tObxUrl);

                if (!utils.string.isNullOrEmpty(tExportUrl)) {
                    window.location.href = tExportUrl;
                }
            }
        } catch (pError) {
            utils.Log.error('Failed to export geometry. Error: ' + pError);
        } finally {
            ProgressUI.endLoading();
        }
    }
}
export default ExportUI;