const translations : {[index: string]:any} = {
    "en": {
        "code": "Code",
        "copyConfigLink": "Copy link to configuration",
        "copied": "Copied",
        "zoomIn": "Zoom In",
        "zoomOut": "Zoom Out",
        "fullscreen": "Fullscreen",
        "snapshot": "Snapshot",
        "exportPlanningData": "Export planning data",
        "exportPdfDatasheet": "Export PDF datasheet",
    },
    "de": {
        "code": "Code",
        "copyConfigLink": "Konfigurationslink kopieren",
        "copied": "Kopiert",
        "zoomIn": "Heranzoomen",
        "zoomOut": "Herauszoomen",
        "fullscreen": "Vollbildansicht",
        "snapshot": "Schnappschuss",
        "exportPlanningData": "Planungsdaten exportieren",
        "exportPdfDatasheet": "Datenblatt PDF export",
    }
};

const translate = (input: string, lang: string) => {
    const candidate = translations[lang][input];

    if (candidate === undefined) {
        return input;
    }

    return candidate;
}

const translateLocalStorage = (input: string) => {
    const lang = localStorage.getItem("lang") || "de";
    return translate(input, lang);
}


export {translate, translateLocalStorage};