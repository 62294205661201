import './index.css';
/**
 * Used for showing a loading animation.
 */
class ProgressUI {
    private static mCalls: number = 0;

    public static beginLoading(): void {
        this.mCalls++;
        if (this.mCalls === 1) {
            document.getElementById('progress')!.style.display = 'block';
        }
    }

    public static endLoading(): void {
        this.mCalls--;
        if (this.mCalls === 0) {
            document.getElementById('progress')!.style.display = 'none';
        }
    }
}

export default ProgressUI;