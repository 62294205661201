import core = egr.wcf.core;
import ArticleData = egr.wcf.eaiws.basket.ArticleData;

import { translateLocalStorage } from "../../helpers/i18n";
import constants from "../../helpers/constants";

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

let copyEl = import('clipboard-copy');

/**
 * Used for showing controls below the property editor by DiNITED
 */
class ControlUI {
    private mPropertyProvider: core.prop.MultiPropertyProvider;
    private mCoreApp: egr.wcf.core.Application;

    constructor(coreApp: core.Application) {
        this.mCoreApp = coreApp;
        this.mPropertyProvider = coreApp.model.selectionProperties;
        this.mPropertyProvider.eventPropertiesChanged.addListener(this.buildUI.bind(this));
    }

    private async buildUI(pResult?: core.prop.PropertyChangedResult) {
        const tContainer: HTMLDivElement = document.getElementById('property-editor') as HTMLDivElement;

        const articleData : ArticleData = (this.mCoreApp.model.selection[0] as unknown as ArticleData);

        // @ts-ignore
        const variantCode : string = (await articleData.getArticleData()).variantCode;

        const tItem: HTMLDivElement = document.createElement('div');
        tItem.id = "dnt-controls";
        tItem.className = 'dnt-controls';
        tItem.innerHTML = `
            <div>
                ${translateLocalStorage("code")} <span class="config_code">${variantCode} <img class="icon-copy" src="${constants.baseUrl}/icon_copy.svg" /></span>
            </div>
            <button href="#" id="link-copy">
                ${translateLocalStorage("copyConfigLink")}
            </button>
        `;

        tContainer.appendChild(tItem);

        const el: Element = document.getElementsByClassName("icon-copy")[0];
        tippy(el, {
            theme: 'custom',
            placement: 'bottom',
            content: translateLocalStorage("copied"),
            hideOnClick: false,
            trigger: "click",
            onShow(instance) {
                setTimeout(() => {
                    instance.hide();
                }, 1000);
            },
        });
        el.addEventListener('click', async () => {
            await (await copyEl)(variantCode);
        });

        const copyLink = document.getElementById("link-copy");

        if (copyLink === null) { return }

        tippy(copyLink, {
            theme: 'custom',
            placement: 'top',
            content: translateLocalStorage("copied"),
            hideOnClick: false,
            trigger: "click",
            onShow(instance) {
                setTimeout(() => {
                    instance.hide();
                }, 1000);
            },
        });

        copyLink.addEventListener('click', async () => {await this.buildUrl()});
    }

    // @ts-ignore
    private async buildUrl() {
        const allProperties = await this.mPropertyProvider.getProperties();
        const editableProperties = allProperties.filter(el => el.editable);

        let getParameters: string = "";

        for (const prop of editableProperties) {
            const key = prop.key;
            const value = prop.getValue()?.value;

            getParameters += `${key}=${value},`;
        }

        getParameters = getParameters.slice(0, -1);

        await (await copyEl)(window.location.origin + window.location.pathname + "?selection=" + getParameters + constants.anchor);
    }

}

export default ControlUI;