import core = egr.wcf.core;
import utils = egr.wcf.utils;
import ProgressUI from '../progress';
import './index.css';
/**
 * For configuring articles.
 * Shows article properties and their options to the user. So he can change properties of an article.
 */
class PropertyEditorUI {
    private mActiveProperty: core.prop.Property | null; // the current by the user clicked property, null if no property is clicked
    private mPropertyProvider: core.prop.MultiPropertyProvider; // current selected element, which provides the properties (will be updated by the core)

    constructor(pPropertyProvider: core.prop.MultiPropertyProvider) {
        this.mPropertyProvider = pPropertyProvider;
        this.mPropertyProvider.eventPropertiesChanged.addListener(this.onPropertiesChanged.bind(this));
    }

    /**
     * Gets properties/classes/choices of the current property provider and creates ui for those.
     */
    private async updatePropertyEditor(): Promise<void> {
        const tContainer: HTMLDivElement = document.getElementById('property-editor') as HTMLDivElement;
        while (tContainer.lastChild) { // remove all html elements, so we start from blank
            tContainer.removeChild(tContainer.lastChild);
        }
        if (this.mPropertyProvider != null) {
            try {
                const tPropertyClasses: Array<core.prop.PropertyClass> = await this.mPropertyProvider.getPropertyClasses();
                const tProperties: Array<core.prop.Property> = await this.mPropertyProvider.getProperties();
                // create a default class which will be used for properties, which belong to no class
                const tDefaultClass: core.prop.PropertyClass = new core.prop.PropertyClass();
                tDefaultClass.key = '';
                tDefaultClass.name = 'Properties';
                tPropertyClasses.push(tDefaultClass);

                tPropertyClasses.forEach((pPropertyClass: core.prop.PropertyClass) => {
                    const tPropertiesOfClass: Array<core.prop.Property> = tProperties.filter((pProperty) => {
                        // if the property has no class, assign "" so it gets the default class we created before
                        const tClassName: string = pProperty.class != null ? pProperty.class : '';

                        // DiNITED: Filter all non changable properties out
                        if (!pProperty.visible || !pProperty.editable) {
                            return false;
                        }

                        return tClassName === pPropertyClass.key;
                    });
                    if (tPropertiesOfClass.length > 0) {
                        const tHtmlPropertyClass: HTMLDivElement = this.createPropertyClass(pPropertyClass, tContainer);
                        tPropertiesOfClass.forEach((pProperty) => this.createPropertyItem(pProperty, tHtmlPropertyClass));
                    }
                });
            } catch (e) {
                console.error('failed to get properties', e);
                tContainer.innerText = 'Migration of article might be required.';
            }
        }
    }

    /**
     * Property classes are like a category for properties.
     */
    private createPropertyClass(pPropertyClass: core.prop.PropertyClass, pParentContainer: HTMLDivElement): HTMLDivElement {
        const tClass: HTMLDivElement = document.createElement('div');
        tClass.className = 'property-class';
        pParentContainer.appendChild(tClass);
        return tClass;
    }

    private createPropertyItem(pProperty: core.prop.Property, pParentContainer: HTMLDivElement): void {
        const tProperty: HTMLDivElement = document.createElement('div');

        tProperty.innerHTML = '<div class="property-title">' + pProperty.getName() + '</div>';
        tProperty.className = 'property'+' '+pProperty.getName().replace(/[\W_]+/g,'-').toLowerCase();
        pParentContainer.appendChild(tProperty);
        if (pProperty.editable && pProperty.visible) {
            this.showPropertyList(pProperty, tProperty);
        }
        // save information in dataset css
        tProperty.dataset.editable = pProperty.editable ? 'true' : 'false';
        tProperty.dataset.visible = pProperty.visible ? 'true' : 'false';
        tProperty.dataset.choiceList = pProperty.choiceList ? 'true' : 'false';
    }

    private async showPropertyList(pProperty: core.prop.Property, position: HTMLElement): Promise<void> {
        this.removeAllPropertyChoices();
        if (this.mActiveProperty !== pProperty) {
            if (pProperty.choiceList) {
                const tPropertyChoices: Array<core.prop.PropertyValue> | null = await pProperty.getChoices();
                if (tPropertyChoices != null) {
                    tPropertyChoices.forEach((pPropertyValue: core.prop.PropertyValue) =>
                        this.createPropertyChoice(pProperty, pPropertyValue, position)
                    );
                }
            } else {
                const tUserInput: string | null = prompt(pProperty.getName());
                if (tUserInput != null) {
                    await pProperty.setValue(tUserInput);
                }
            }
            this.mActiveProperty = pProperty;
        } else {
            this.mActiveProperty = null;
        }
    }

    private removeAllPropertyChoices(): void {
        const tPropertyChoices: HTMLCollectionOf<Element> = document.getElementsByClassName('property-choice');
        let tPropertyChoice: Element | null = tPropertyChoices.item(0);
        while (tPropertyChoice != null) {
            tPropertyChoice.remove();
            tPropertyChoice = tPropertyChoices.item(0);
        }
    }

    private createPropertyChoice(
        pProperty: core.prop.Property,
        pPropertyValue: core.prop.PropertyValue,
        pParentPropertyItem: HTMLElement
    ): void {
        const tPropertyChoice: HTMLDivElement = document.createElement('div');
        tPropertyChoice.className = 'property-choice' +' '+ pPropertyValue.value+ ' ' + pProperty.getName().replace(/[\W_]+/g,'-').toLowerCase() + (pProperty.getValue()!.value === pPropertyValue.value ? ' selected' : '');
        tPropertyChoice.onclick = async () => {
            ProgressUI.beginLoading();
            await pProperty.setValue(pPropertyValue.value);
            ProgressUI.endLoading();
        };
        pParentPropertyItem.appendChild(tPropertyChoice);
        if (!utils.string.isNullOrEmpty(pPropertyValue.largeIcon)) {
            const tIcon: HTMLImageElement = document.createElement('img');
            tIcon.src = pPropertyValue.largeIcon;
            tPropertyChoice.appendChild(tIcon);
        }
        if (!utils.string.isNullOrEmpty(pPropertyValue.text)) {
            const tLabel: HTMLDivElement = document.createElement('div');
            tLabel.innerText = pPropertyValue.text;
            tPropertyChoice.appendChild(tLabel);
        }
    }

    private async onPropertiesChanged(pResult?: core.prop.PropertyChangedResult): Promise<void> {
        if (pResult !== core.prop.PropertyChangedResult.Nothing) {
            this.mActiveProperty = null;
            await this.updatePropertyEditor();
        }
    }
}
export default PropertyEditorUI;