import core = egr.wcf.core;
import './index.css';
import utils = egr.wcf.utils;
import EaiwsSession = egr.wcf.eaiws.EaiwsSession;
import ArticleManager = egr.wcf.cf.ArticleManager;
import eaiws = egr.wcf.eaiws;
import cf = egr.wcf.cf;
import MainArticleElement = egr.wcf.cf.MainArticleElement;
import constants from "../../helpers/constants";
import ProgressUI from "../progress";
import {translateLocalStorage} from "../../helpers/i18n";

/**
 * This components creates the views and the controls of the viewer.
 */

export interface ViewerOptions {
    disableCameraPanning: boolean;
    limitCameraDistanceByElementRadius: boolean;
}
class ViewerUI {
    private mCoreApp: core.Application;
    private mOptions: ViewerOptions;
    private mSession: EaiwsSession;
    private mArticleManager: ArticleManager;

    private isToggled: boolean = false;
    private canvasWidth: number;

    /**
     * @param pMultiView If true, four views will be created instead of one.
     */
    constructor(pCoreApp: core.Application, pOptions: ViewerOptions, mSession: EaiwsSession, mArticleManager: ArticleManager) {
        this.mCoreApp = pCoreApp;
        this.mOptions = pOptions;
        this.mSession = mSession;
        this.mArticleManager = mArticleManager;
        if (this.mOptions.disableCameraPanning) {
            const cameraControl = pCoreApp.viewer.view.cameraControl;
            cameraControl.panningEnabled = false;
            cameraControl.setFixedTarget(BABYLON.Vector3.Zero());
            cameraControl.dblClickZoomToFitOptions.adjustFixedTarget = false;
            // cameraControl.orbitInertia = 0.95; // uncomment if you want some movement after the user releases mouse button
        }
        // add user interaction with the articles in the 3d view
        const tDefaultTool: core.tool.SelectionDefault = new core.tool.SelectionDefault(this.mCoreApp);
        tDefaultTool.deselectionEnabled = false;
        tDefaultTool.showMainElementSelection = false;
        tDefaultTool.showElementInteractors = true;
        this.mCoreApp.tools.defaultTool = tDefaultTool;
        this.mCoreApp.tools.startDefaultTool();

        // add shadows
        this.mCoreApp.rendering.addShadowGenerator(new core.rendering.ShadowPlane(this.mCoreApp)); // shadows on the floor
        this.mCoreApp.rendering.addShadowGenerator(new core.rendering.ShadowMapping(this.mCoreApp)); // (directional) shadows on the objects
        // this.mCoreApp.viewer.ssaoQuality = "Off"; // screenSpaceAmbientOcclusion shadows, can be lowered/increased/disabled (check performance!), only available with webGL2

        // add camera controls
        const tContainer: HTMLDivElement = document.getElementById('pcon-viewer') as HTMLDivElement;

        const topLeftControlContainer: HTMLElement = document.createElement('div');
        topLeftControlContainer.className = "pcon-viewer-control-tl";

        const topRightControlContainer: HTMLElement = document.createElement('div');
        topRightControlContainer.className = "pcon-viewer-control-tr";

        const topTitle: HTMLElement = document.createElement('div');

        var baseArticleNumber:any = null;
        var baseArticleName:any = null;

        baseArticleNumber = localStorage.getItem('currentBaseArticleNumber');
        baseArticleName = localStorage.getItem('currentBaseArticleName');

        topTitle.innerHTML = baseArticleName || baseArticleNumber;
        topTitle.className = "pcon-top-titel";

        const zoomInButton: HTMLElement = document.createElement('button');
        zoomInButton.innerHTML = `<img src=${constants.baseUrl}/icon_edit_plus.svg />`;
        zoomInButton.className = "pcon-button zoom-in";
        zoomInButton.title = translateLocalStorage("zoomIn");
        zoomInButton.addEventListener('click', this.zoomIn.bind(this));

        const zoomOutButton: HTMLElement = document.createElement('button');
        zoomOutButton.innerHTML = `<img src=${constants.baseUrl}/icon_edit_minus.svg />`;
        zoomOutButton.className = "pcon-button zoom-out";
        zoomOutButton.title = translateLocalStorage("zoomOut");
        zoomOutButton.addEventListener('click', this.zoomOut.bind(this));

        const fullScreenButton: HTMLElement = document.createElement('button');
        fullScreenButton.innerHTML = `<img src=${constants.baseUrl}/icon_edit_move.svg />`;
        fullScreenButton.className = "pcon-button fullscreen";
        fullScreenButton.title = translateLocalStorage("fullscreen");
        fullScreenButton.addEventListener('click', this.fullscreen.bind(this));

        topLeftControlContainer.appendChild(topTitle);

        topRightControlContainer.appendChild(zoomInButton);
        topRightControlContainer.appendChild(zoomOutButton);
        topRightControlContainer.appendChild(fullScreenButton);

        tContainer.appendChild(topLeftControlContainer);
        tContainer.appendChild(topRightControlContainer);

        const bottomCameraButton: HTMLElement = document.createElement('button');
        bottomCameraButton.innerHTML = `<img src=${constants.baseUrl}/icon_snapshot.svg />`;
        bottomCameraButton.className = "pcon-button camera";
        bottomCameraButton.title = translateLocalStorage("snapshot");
        bottomCameraButton.addEventListener('click', this.screenShot.bind(this));

        tContainer.appendChild(bottomCameraButton);

        const exportDropDown: HTMLSelectElement = this.createExportDropdown();
        exportDropDown.className = "dnt-dropdown-export";
        exportDropDown.addEventListener('change', (event: Event) => {
            if (!(event.target instanceof HTMLSelectElement)) {
                return;
            }
            if (event.target.value === "Export") {
                return;
            }
            this.onDownloadClicked(mArticleManager, event.target.value);
        });
        exportDropDown.title = translateLocalStorage("exportPlanningData");
        tContainer.appendChild(exportDropDown);

        const bottomPDF: HTMLElement = document.createElement('button');
        bottomPDF.innerHTML = `<img src=${constants.baseUrl}/icon_document.svg />`;
        bottomPDF.className = "pcon-button pdf";
        bottomPDF.title = translateLocalStorage("exportPdfDatasheet");
        bottomPDF.addEventListener('click', this.createPDF.bind(this));

        tContainer.appendChild(bottomPDF);

        window.addEventListener("resize", this.setCanvas);
        this.setCanvas();
    }

    private setCanvas(event?: any): void {
        const appWidth : number = document.getElementById("app-container")!.offsetWidth;
        if (window.outerWidth < 821) {
            this.canvasWidth = appWidth;
        } else {
            this.canvasWidth = appWidth - document.getElementById("property-editor")!.offsetWidth;
        }

        document.getElementById("pcon-viewer")!.style.width = this.canvasWidth + "px";
        document.getElementsByTagName("canvas")[0].width = 2*this.canvasWidth;
        document.getElementsByTagName("canvas")[0].style.width = this.canvasWidth + "px";

        // if setCanvas was fired by the resize event listener, we dont want it to fire a resize event again
        if (event !== undefined && event !== null) {
            return;
        }

        window.dispatchEvent(new Event('resize'));
    }

    private createExportDropdown(): HTMLSelectElement {
        const fileExportDropdown: HTMLSelectElement = document.createElement('select');

        const values = ['Export', '3DS', 'DXF'];

        for (const val of values) {
            const option = document.createElement('option');
            option.value = val;
            option.text = val;
            fileExportDropdown.appendChild(option);
        }

        return fileExportDropdown;
    }

    public zoomIn(): void {
        this.mCoreApp.viewer.view.cameraControl.zoomBy(constants.zoomInButtonValue);
    }

    public zoomOut(): void {
        this.mCoreApp.viewer.view.cameraControl.zoomBy(constants.zoomOutButtonValue);
    }

    private async createPDF() {
        await this.onPdfDownloadClicked(this.mArticleManager, this.mSession);
    }

    private async onPdfDownloadClicked(pArticleManager: ArticleManager, pSession: EaiwsSession): Promise<void> {
        const BasketViewConfigCollapsed: Readonly<eaiws.basket.BasketViewConfig> = ((): eaiws.basket.BasketViewConfig => {
            const config: eaiws.basket.BasketViewConfig = new eaiws.basket.BasketViewConfig();

            config.name = 'compact';
            config.viewId = '2e21f798-2d8d-4af1-9fba-748ab606195a';
            config.displayMode = 'Sorted';
            config.mergeMode = 'Compact';

            return config;
        })();

        try {
            const availableViews: Array<eaiws.basket.BasketViewConfig> = await pSession.basket.getBasketViewConfigs();
            let compactViewExists: boolean = false;
            availableViews.forEach((value: eaiws.basket.BasketViewConfig) => {
                if (value.viewId === BasketViewConfigCollapsed.viewId) {
                    compactViewExists = true;
                }
            });

            if (!compactViewExists) {
                await pSession.basket.addBasketView(BasketViewConfigCollapsed);
            }

            const tArticles: Array<MainArticleElement> = pArticleManager.getAllMainArticles();
            const itemIds: Array<string> = [];
            for (const tArticle of tArticles) {
                if (tArticle.basketId != null) {
                    itemIds.push(tArticle.basketId)
                }
            }

            const tPdfUrl: string | undefined = await utils.async.ajax<string>(
                'POST',
                utils.string.joinPath(
                    pSession.baseUrl,
                    '/EAIWS/plugins/Reporter/template/wcf_Wagner_5fc7a7fcd1644_hwgd4/generate'
                ),
                JSON.stringify({
                    sessionId: pSession.sessionId,
                    viewId: BasketViewConfigCollapsed.viewId,
                    items: itemIds,
                    matchBasketItemIds: true
                }),
                { dataType: 'text' }
            );

            if (!utils.string.isNullOrEmpty(tPdfUrl)) {
                const tLink: HTMLAnchorElement = document.createElement('a');
                tLink.style.display = 'none';
                document.body.appendChild(tLink);

                tLink.href = tPdfUrl;
                tLink.target = "_blank";
                tLink.download = 'report.pdf';
                tLink.click();

                URL.revokeObjectURL(tPdfUrl);
                document.body.removeChild(tLink);
            }
        } catch {
            console.log('something went wrong');
        }
    }

    public async fullscreen(): Promise<void> {
        // fullscreen is only available for desktop devices
        const tPropertyEditor: HTMLDivElement = document.getElementById('property-editor') as HTMLDivElement;

        //Toggle visibility
        tPropertyEditor.className = (this.isToggled) ? "" : 'invisible';

        this.isToggled = !this.isToggled;

        window.dispatchEvent(new Event('resize'));
    }

    public async screenShot(): Promise<void> {
        const tBlob: Blob = await this.mCoreApp.viewer.createScreenshot({
            width: 800,
            height: 600,
            mimeType: 'image/jpeg'
        }, true);

        if (navigator.msSaveBlob != null) {
            // IE
            navigator.msSaveBlob(tBlob, 'screenshot.jpg');
        } else {
            const tLink: HTMLAnchorElement = document.createElement('a');
            tLink.style.display = 'none';
            document.body.appendChild(tLink);

            const tUrl: string = URL.createObjectURL(tBlob);
            tLink.href = tUrl;
            tLink.download = 'screenshot.jpg';
            tLink.click();

            URL.revokeObjectURL(tUrl);
            document.body.removeChild(tLink);
        }
    }


    /**
     * If we have multiple articles in the scene (i.e. by loading a .pec), then we need to show the user which one is currently selected by a bounding box.
     */
    public allowMainArticleSelection(pValue: boolean): void {
        if (this.mCoreApp.tools.defaultTool instanceof core.tool.SelectionDefault) {
            this.mCoreApp.tools.defaultTool.showMainElementSelection = pValue;
            this.mCoreApp.tools.defaultTool.deselectionEnabled = pValue;
        }
    }

    // @ts-ignore
    private async onDownloadClicked(pArticleManager: cf.ArticleManager, pFormat: string): Promise<void> {
        ProgressUI.beginLoading();
        let tExportOptions: Array<string> | null = null;
        switch (pFormat) {
            case 'DWG':
                tExportOptions = [
                    'format=DWG',
                    'hideSubArticles=false',
                    'no2D=true',
                    'textures=true',
                    'materials=true'
                ];
                break;

            case 'DXF':
                tExportOptions = [
                    'format=DWG',
                    'dxf=true',
                    'hideSubArticles=false',
                    'no2D=true',
                    'textures=true',
                    'materials=true'
                ];
                break;

            case '3DS':
                tExportOptions = [
                    'format=3DS',
                    'hideSubArticles=false',
                    'textures=true'
                ];
                break;

            case 'SKP':
                tExportOptions = [
                    'format=SKP',
                    'hideSubArticles=false',
                    'no2D=true',
                    'textures=false',
                    'textureToColor=true'
                ];
                break;
            default:
                break;
        }
        if (tExportOptions == null) {
            return;
        }

        try {
            const tArticles: Array<cf.MainArticleElement> = pArticleManager.getAllMainArticles();

            // export obx
            const tObxUrl: string = await pArticleManager.exportObx(tArticles);

            // create temporary set article
            const tFolderId: string = await pArticleManager.session.basket.insertFolder(null, null, 'Set');
            const tSetId: string = await pArticleManager.session.basket.convertToSetArticle(tFolderId);

            // insert obx into set article
            const tPastedIds: Array<string> = await pArticleManager.session.basket.paste(tSetId, null, tObxUrl);

            // add to set article
            if (tPastedIds != null) {
                await pArticleManager.session.basket.addToSetArticle(tPastedIds);

                // export geometry
                const tExportUrl: string = await pArticleManager.session.basket.getExportedGeometry(tSetId, tExportOptions);

                // delete temporary set
                const tOptions: eaiws.basket.DeleteItemsOptions = new eaiws.basket.DeleteItemsOptions();
                tOptions.subItems = true;
                await pArticleManager.session.basket.deleteItems([tSetId], tOptions);

                // delete temporary obx
                await pArticleManager.session.deleteFile(tObxUrl);

                if (!utils.string.isNullOrEmpty(tExportUrl)) {
                    window.location.href = tExportUrl;
                }
            }
        } catch (pError) {
            utils.Log.error('Failed to export geometry. Error: ' + pError);
        } finally {
            ProgressUI.endLoading();
        }
    }

    /**
     * Should by called after inserting new elements.
     */
    public resetCamera(): void {
        if (this.mOptions.disableCameraPanning) {
            this.mCoreApp.viewer.view.cameraControl.setFixedTarget(this.getCenterOfSceneElement());
        }
        if (this.mOptions.limitCameraDistanceByElementRadius) {
            const tRadiusMultiplier: number = 3; // should be changed to value you want
            const tMaxZoomRadius: number = this.getRadiusOfSceneElement() * tRadiusMultiplier; // tMaxZoomRadius can also be a fixed value, but we take size of element into count
            this.mCoreApp.viewer.view.cameraControl.setNavigationArea(tMaxZoomRadius, this.getCenterOfSceneElement());
        }
        this.mCoreApp.viewer.view.cameraControl.setPosition(core.view.CameraControl.DEFAULT_CAMERA_POSITION);
        this.mCoreApp.viewer.view.cameraControl.zoomToFitElements(null);
        this.mCoreApp.viewer.requestRenderFrame();
    }
    private getCenterOfSceneElement(): BABYLON.Vector3 {
        if (this.mCoreApp.model.elements.length > 0 && this.mCoreApp.model.elements[0].boundingBox.isValid()) {
            return this.mCoreApp.model.elements[0].boundingBox.getCenter();
        }
        return BABYLON.Vector3.Zero();
    }
    private getRadiusOfSceneElement(): number {
        if (this.mCoreApp.model.elements.length > 0 && this.mCoreApp.model.elements[0].boundingBox.isValid()) {
            return this.mCoreApp.model.elements[0].boundingBox.getRadius();
        }
        return 20; // 20 meter, if nothing is in the scene
    }
}
export default ViewerUI;