import catalog = egr.wcf.eaiws.catalog;
import './index.css';
/**
 * UI for navigation through a catalog.
 * Calls callback if an articles was clicked, so he can be inserted.
 */
class CatalogUI {
    private mCatalogService: catalog.CatalogService;
    private mCatalogPath: Array<string>; // stores the current path in the catalog ["root name", "level1 name", "level2 name", ...]
    private mOnInsertArticle: (pItem: catalog.ArticleCatalogItem) => Promise<void>; // callback if user clicks on a article
    private mOnInsertContainer: (pItem: catalog.CatalogItem) => Promise<void>; // callback if user clicks on a container
    private mShowingSearchResults: boolean = false; // indicates if we are currently showing the results of a search query
    private mSearchBar: HTMLDivElement;

    constructor(
        pCatalogService: catalog.CatalogService,
        pOnInsertArticle: (pItem: catalog.ArticleCatalogItem) => Promise<void>,
        pOnInsertContainer: (pItem: catalog.CatalogItem) => Promise<void>
    ) {
        this.mCatalogService = pCatalogService;
        this.mCatalogPath = []; // shows all entries, can be filled up to start with an more specific path
        this.mOnInsertArticle = pOnInsertArticle;
        this.mOnInsertContainer = pOnInsertContainer;
        this.mSearchBar = this.createSearchBar(document.getElementById('catalog') as HTMLDivElement);
        void this.createCatalogItems();
    }

    private async createCatalogItems(): Promise<void> {
        // show search bar only if we have entered a catalog, because we can not search over all catalogs (of all manufacturers)
        if (this.mCatalogPath.length === 0) {
            this.mSearchBar.style.display = 'none';
        } else {
            this.mSearchBar.style.display = 'block';
        }
        this.removeAllCatalogItems();
        const tOptions: catalog.LookupOptions = new catalog.LookupOptions();
        tOptions.itemTypes = [
            'Article',
            'Folder',
            'Container',
            'Information'
        ];
        const tCatalogItems: Array<catalog.CatalogItem> = await this.mCatalogService.listCatalogItems(this.mCatalogPath, tOptions);
        const tContainer: HTMLDivElement = document.getElementById('catalog') as HTMLDivElement;
        if (this.mCatalogPath.length > 0) {
            this.createBackButton(tContainer);
        }
        tCatalogItems.forEach((pItem) => this.createCatalogItem(pItem, tContainer));
    }

    private createCatalogItem(pCatalogItem: catalog.CatalogItem, pParentContainer: HTMLDivElement): void {
        const tItem: HTMLDivElement = document.createElement('div');
        tItem.className = 'catalog-item' + (pCatalogItem.type === 'Folder' ? ' folder' : '');
        tItem.onclick = this.onItemClick.bind(this, pCatalogItem);
        tItem.innerHTML = `
            <img class="catalog-item-icon" src=${pCatalogItem.icon}></img>
            <div class="catalog-item-label">${pCatalogItem.label}</div>
        `;
        pParentContainer.appendChild(tItem);
    }

    /**
     * Creates a back button to go back to previous folder.
     */
    private createBackButton(pParentContainer: HTMLDivElement): void {
        const tItem: HTMLDivElement = document.createElement('div');
        tItem.className = 'catalog-item folder';
        tItem.onclick = this.onBackClick.bind(this);
        tItem.innerHTML = `
            <div class="catalog-item-label">back</div>
        `;
        pParentContainer.appendChild(tItem);
    }

    private createSearchBar(pParentContainer: HTMLDivElement): HTMLDivElement {
        const tSearchBar: HTMLDivElement = document.createElement('div');
        tSearchBar.className = 'catalog-search';
        pParentContainer.appendChild(tSearchBar);

        const tInput: HTMLInputElement = document.createElement('input');
        tInput.className = 'catalog-search-input';
        tSearchBar.appendChild(tInput);

        const tSubmit: HTMLButtonElement = document.createElement('button');
        tSubmit.innerText = 'search';
        tSubmit.onclick = this.onSearchCatalogClick.bind(this, tInput);
        tSearchBar.appendChild(tSubmit);
        return tSearchBar;
    }

    private async onItemClick(pItem: catalog.CatalogItem): Promise<void> {
        if (pItem.type === 'Article') {
            if (pItem instanceof catalog.ArticleCatalogItem) {
                await this.mOnInsertArticle(pItem);
            }
        } else if (pItem.type === 'Container') {
            await this.mOnInsertContainer(pItem);
        } else if (pItem.type === 'Information') {
            this.downloadPDF(pItem);
        } else if (pItem.type === 'Folder') {
            if (this.mShowingSearchResults) { // if the folder is from a search result, we need to setup the catalog path to that folder
                this.mCatalogPath = await this.mCatalogService.getCatalogPath(pItem.catalogId, pItem.catalogNodeKey);
                this.mShowingSearchResults = false;
            } else {
                this.mCatalogPath.push(pItem.name);
            }
            await this.createCatalogItems();
        }
    }

    /**
     * Searches resources for a PDF and downloads it.
     */
    private downloadPDF(pItem: catalog.CatalogItem): void {
        if (pItem.resources != null) {
            for (const tResource of pItem.resources) {
                const tValue: string | undefined = tResource.value;
                if (tValue != null && tValue.startsWith('application/pdf')) {
                    const tUrl: string = tValue.substr(tValue.indexOf(';') + 1);
                    window.location.href = tUrl;
                    break;
                }
            }
        }
    }

    private async onBackClick(): Promise<void> {
        if (this.mShowingSearchResults) {
            this.mShowingSearchResults = false;
        } else {
            this.mCatalogPath.pop();
        }
        await this.createCatalogItems();
    }

    private removeAllCatalogItems(): void {
        const tPropertyChoices: HTMLCollectionOf<Element> = document.getElementsByClassName('catalog-item');
        let tPropertyChoice: Element | null = tPropertyChoices.item(0);
        while (tPropertyChoice != null) {
            tPropertyChoice.remove();
            tPropertyChoice = tPropertyChoices.item(0);
        }
    }

    private async onSearchCatalogClick(pInputField: HTMLInputElement): Promise<void> {
        if (this.mCatalogPath == null || this.mCatalogPath.length === 0 || pInputField.value === '') {
            return;
        }
        this.removeAllCatalogItems();
        this.mShowingSearchResults = true;
        const tContainer: HTMLDivElement = document.getElementById('catalog') as HTMLDivElement;

        const tParameterSet: catalog.SearchParameterSet = new catalog.SearchParameterSet();
        tParameterSet.catalogIds = [this.mCatalogPath[0]]; // only search in the whole catalog is currently possible
        tParameterSet.query = pInputField.value;
        tParameterSet.numberOfHits = 100;
        tParameterSet.flags = ['FolderText'];
        const tOptions: catalog.LookupOptions = new catalog.LookupOptions();
        tOptions.itemTypes = [
            'Article',
            'Folder',
            'Container',
            'Information'
        ];
        const tFoundItems: catalog.TopCatalogItems | undefined = await this.mCatalogService.searchCatalogItems(tParameterSet, tOptions);
        this.createBackButton(tContainer);
        if (tFoundItems != null) {
            tFoundItems.scoredItems.forEach((pItem) => {
                this.createCatalogItem(pItem.item, tContainer);
            });
        }
    }
}
export default CatalogUI;