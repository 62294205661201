import eaiws = egr.wcf.eaiws;
import catalog = egr.wcf.eaiws.catalog;
import utils = egr.wcf.utils;
import cf = egr.wcf.cf;
import core = egr.wcf.core;
import CatalogUI from './components/catalog';
import BasketUI from './components/basket';
import PropertyEditorUI from './components/property-editor';
import ViewerUI from './components/viewer';
import OapUI from './components/oap';
import ExportUI from './components/export';
import PersistenceUI from './components/persistence';
import ProgressUI from './components/progress';
import './index.css';
import { isIOSBrowser } from './utils';
import ControlUI from "./components/controls";
import constants from "./helpers/constants";
class App {
    protected readonly DEFAULT_GATEKEEPER_ID: string = '5fc7a7fcd1644';
    protected mSession: eaiws.EaiwsSession;
    protected mBasketUI: BasketUI;
    protected mViewerUI: ViewerUI;
    protected mCoreApp: core.Application;
    protected mArticleManager: cf.ArticleManager;
    protected mCatalogUI: CatalogUI;
    protected mPropertyEditorUI: PropertyEditorUI;
    protected mOapUI: OapUI;
    protected mExportUI: ExportUI;
    protected mPersistenceUI: PersistenceUI;
    protected mControlUI: ControlUI;
    constructor() {
        void this.init();
    }

    public async init(): Promise<void> {
        if (!BABYLON.Engine.isSupported()) {
            alert('WebGL required!');
            return;
        }
        // enable hover effects for icons (like oap-interactors)
        if (!isIOSBrowser() && document.documentElement != null) { // on ios this leads to click icons twice, so we don't add it here
            document.documentElement.classList.add("wcf-hover-enabled");
        }
        // setup w-cf

        // const tRootPath: string = utils.string.getFilePath(window.location.pathname);
        const tRootPath: string = constants.baseUrl;

        utils.wcfLibsPath = tRootPath + "w-cf/libs/";

        ProgressUI.beginLoading();
        // create session
        const tGatekeeperId: string = this.DEFAULT_GATEKEEPER_ID;
        let tGatekeeperResponse: { keepAliveInterval: number, server: string, sessionId: string };
        try {
            tGatekeeperResponse =
                await utils.async.ajax(
                    'POST',
                    'https://eaiws-server.pcon-solutions.com/v2/session/' + tGatekeeperId,
                    undefined,
                    {
                        retryAttempts: 1,
                        ignoreGlobalErrorHandler: true,
                        timeout: 10000
                    }
                );
        } catch (pError) {
            utils.Log.info('Failed to start gatekeeper session, using fallback server: ' + pError);
            tGatekeeperResponse = await utils.async.ajax(
                'POST',
                'https://eaiws-server-fallback.pcon-solutions.com/v2/session/' + tGatekeeperId,
                undefined,
                {
                    dataType: 'json'
                }
            );
        }
        this.mSession = new eaiws.EaiwsSession();
        this.mSession.connect(
            tGatekeeperResponse.server,
            tGatekeeperResponse.sessionId,
            tGatekeeperResponse.keepAliveInterval * 1000 // keep alive is given in seconds
        );

        // setup languages (if first language is not available, the second will be used and so on)
        await this.mSession.catalog.setLanguages(['de', 'en', 'fr']);
        await this.mSession.basket.setLanguages(['de', 'en', 'fr']);

        // setup core
        this.mCoreApp = new core.Application();
        this.mCoreApp.applicationName = "EAIWS-DEV-EXAMPLE";
        this.mCoreApp.applicationVersion = "1.3.10";
        this.mCoreApp.dataPath = tRootPath + 'w-cf/data/';
        const tAppOptions: core.AppInitOptions = new core.AppInitOptions();
        tAppOptions.disableWebGL2Support = true;
        this.mCoreApp.initialize(document.getElementById('pcon-viewer') as HTMLDivElement, tAppOptions);
        utils.Log.info("W-CF " + this.mCoreApp.version + " build: " + this.mCoreApp.buildInfo);

        this.mArticleManager = new cf.ArticleManager(this.mCoreApp, this.mSession);
        this.mArticleManager.setGfjBasketIdsEnabled(true);

        // add all ui components
        this.mViewerUI = new ViewerUI(
            this.mCoreApp,
            {
                disableCameraPanning: false,
                limitCameraDistanceByElementRadius: false
            },
            this.mSession,
            this.mArticleManager
        );
        this.mCatalogUI = new CatalogUI(this.mSession.catalog, this.onInsertCatalogArticle.bind(this), this.onInsertCatalogContainer.bind(this));
        this.mPropertyEditorUI = new PropertyEditorUI(this.mCoreApp.model.selectionProperties);
        this.mOapUI = new OapUI(this.mCoreApp.model.selectionProperties, this.mCoreApp.appCallbacks);
        this.mBasketUI = new BasketUI(this.mArticleManager, this.onBasketItemClicked.bind(this));
        this.mExportUI = new ExportUI(this.mArticleManager, this.mCoreApp.viewer);
        this.mPersistenceUI = new PersistenceUI(this.mArticleManager, this.onLoadFile.bind(this));
        this.mControlUI = new ControlUI(this.mCoreApp);

        if (tGatekeeperId === this.DEFAULT_GATEKEEPER_ID) {
            await this.insertInitialArticle();
        }

        this.parseParameter();

        ProgressUI.endLoading();
    }

    /**
     * Gets gatekeeper id from user, by user input or by a given url parameter (?gatekeeper_id=XXXXXX).
    private getGatekeeperId(): string {
        const tGateKeeperIdFromUrl: string | null = utils.string.getUrlParamValue(window.location.href, 'gatekeeper_id');
        let tGatekeeperId: string | null = tGateKeeperIdFromUrl;
        if (utils.string.isNullOrEmpty(tGatekeeperId)) {
            tGatekeeperId = prompt('please enter gatekeeper id:', this.DEFAULT_GATEKEEPER_ID);
            if (utils.string.isNullOrEmpty(tGatekeeperId)) { // fallback to default, if no valid input
                tGatekeeperId = this.DEFAULT_GATEKEEPER_ID;
            }
        }
        return tGatekeeperId;
    }
     */

    private async parseParameter() {
        const sGetParameters: string | null = utils.string.getUrlParamValue(window.location.href, 'selection')

        if (sGetParameters === null) {
            return;
        }

        console.log("Parse GET Parameters");

        let allProperties = await this.mCoreApp.model.selectionProperties.getProperties();

        // we only need editable properties
        const editableProperties = allProperties.filter(el => el.editable);

        // match sGetParameters with possible candidates and choices
        for (const selection of sGetParameters.split(",")) {

            let key: string|undefined, value : string|undefined;
            [key, value] = selection.split("=");


            // basic key, value sanitizing
            if (key === "" || key === undefined || value === "" || value === undefined) {
                return;
            }

            const propertyCandidate = editableProperties.find(el => el.key === key);

            if (propertyCandidate === undefined) {
                continue;
            }

            const choices: core.prop.PropertyValue[] | null = await propertyCandidate.getChoices();

            // if this is null, a non editable property must have been set which means something went wrong
            if (choices === null) {
                return;
            }

            const choiceCandidate: core.prop.PropertyValue | undefined = choices.find(el => el.value === value);

            if (choiceCandidate === undefined) {
                return;
            }

            await propertyCandidate.setValue(choiceCandidate.value);
        }
    }

    private async onInsertCatalogArticle(pArticle: catalog.ArticleCatalogItem): Promise<void> {
        console.log('App::onInsertCatalogArticle', pArticle);
        ProgressUI.beginLoading();
        await this.removeAllElements();

        const tElement: cf.MainArticleElement = await this.mArticleManager.insertArticle(pArticle);
        this.mCoreApp.model.addElement(tElement); // we need to add it also to the model manager, or we wont see the new article
        this.mCoreApp.model.setSelection([tElement]);
        this.mViewerUI.resetCamera();
        this.mViewerUI.allowMainArticleSelection(this.mCoreApp.model.elements.length > 1);
        await this.mBasketUI.updateBasket();
        ProgressUI.endLoading();
    }

    private async onInsertCatalogContainer(pContainer: catalog.CatalogItem): Promise<void> {
        console.log('App::onInsertCatalogContainer', pContainer);
        ProgressUI.beginLoading();
        await this.removeAllElements();

        const tPecImport: cf.io.PecImport = new cf.io.PecImport(this.mArticleManager);
        const tElements: Array<core.mdl.SceneElement> = await tPecImport.importFromCatalog(pContainer);
        tElements.forEach((pElement) => this.mCoreApp.model.addElement(pElement));

        this.mViewerUI.resetCamera();
        this.mViewerUI.allowMainArticleSelection(this.mCoreApp.model.elements.length > 1);
        await this.mBasketUI.updateBasket();
        ProgressUI.endLoading();
    }

    private async removeAllElements(): Promise<void> {
        while (this.mCoreApp.model.elements.length > 0) {
            this.mCoreApp.model.removeElement(this.mCoreApp.model.elements[0]);
        }
        await this.mArticleManager.synchronizeSession(false); // we need to tell the server, that we deleted items
    }

    /**
     * Select article item if it was clicked in the basket.
     */
    private onBasketItemClicked(pItem: cf.ArticleElement, pEvent: MouseEvent): void {
        console.log('App::onBasketItemClicked', pItem);
        pEvent.stopImmediatePropagation();
        if (pItem instanceof cf.MainArticleElement) {
            this.mCoreApp.model.setSelection([pItem]);
        } else if (pItem instanceof cf.SubArticleElement) {
            this.mCoreApp.model.setSubElementSelection(pItem.getMainArticle(), pItem);
        }
    }

    /**
     * Loads an old planning.
     * @param pFile Loaded .obk/.pec file as binary
     */
    private async onLoadFile(pFile: Blob, pFileType: 'obk' | 'pec'): Promise<void> {
        ProgressUI.beginLoading();
        this.mCoreApp.document.clear(); // removes everything from the scene, so we can start from scratch
        if (pFileType === 'obk') {
            const tUrl: string | null = await this.mArticleManager.session.uploadFile('Project', pFile);
            if (utils.string.isNullOrEmpty(tUrl)) {
                alert('failed to upload file');
                return;
            }
            await this.mSession.session.loadSession(tUrl);
            await this.mSession.deleteFile(tUrl); // we don't need the file anymore
            await this.mArticleManager.importFromSession();
        } else if (pFileType === 'pec') {
            const tPecImport: cf.io.PecImport = new cf.io.PecImport(this.mArticleManager);
            try {
                const tElements: Array<core.mdl.SceneElement> = await tPecImport.import(pFile);
                const tInsertCommand: core.cmd.InsertElements = new core.cmd.InsertElements(this.mCoreApp, tElements);
                await this.mCoreApp.commands.executeCommand(tInsertCommand);
            } catch (e) {
                console.error('failed to load pec', e);
            }
        }
        this.mViewerUI.resetCamera();
        await this.mBasketUI.updateBasket();
        if (this.mCoreApp.model.elements.length > 0) {
            this.mCoreApp.model.setSelection([this.mCoreApp.model.elements[0]]);
        }
        this.mViewerUI.allowMainArticleSelection(this.mCoreApp.model.elements.length > 1);
        ProgressUI.endLoading();
    }

    /**
     * Example for inserting a specific configuration of an article.
     *
     * The key to get a specific configuration is the variant code.
     * He can be received by getArticleData() (i.e. type in console: (await app.mCoreApp.model.selection[0].getArticleData()).variantCode).
     * So you could configure an article as you want, get its variant code and insert him here.
     * The other data will be logged, if you insert an article by onCatalogArticleClicked().
     *
     * If you want to insert a more complex article with sub articles, you could save an .obk and load it on startup.
     */
    private async insertInitialArticle(): Promise<void> {
        var baseArticleNumber:any = 'WCY';
        if(localStorage.getItem('currentBaseArticleNumber')) {
            baseArticleNumber = localStorage.getItem('currentBaseArticleNumber');
        }
        if(localStorage.getItem('currentLanguage')) {
            if(localStorage.getItem('currentLanguage') == "en"){
                await this.mSession.catalog.setLanguages(['en']);
                await this.mSession.basket.setLanguages(['en']);
            }
            else if(localStorage.getItem('currentLanguage') == "fr"){
                await this.mSession.catalog.setLanguages(['fr']);
                await this.mSession.basket.setLanguages(['fr']);
            }
        }

        const searchParameterSet: catalog.SearchArticleParameterSet = new catalog.SearchArticleParameterSet();
        searchParameterSet.catalogIds = ['wag:0'];
        searchParameterSet.baseArticleNumber = baseArticleNumber;
        searchParameterSet.numberOfHits = 1;
        const lookupOptions: catalog.LookupOptions = new catalog.LookupOptions();
        lookupOptions.displayMode = 'All';
        const foundItems: catalog.TopCatalogItems | undefined = await this.mSession.catalog.searchArticle(searchParameterSet, lookupOptions);
        const item: catalog.CatalogItem | undefined = foundItems?.scoredItems[0]?.item;
        if (item instanceof catalog.ArticleCatalogItem) {
            // to insert a specific variant, we set the variant code here | this is optional
            item.varCodeType = 'OFML';
            item.variantCode = '';
            // this inserts the item found by the article search
            await this.onInsertCatalogArticle(item);
        } else {
            console.log('Article could not be found.');
        }
    }
}
export default App;
window.onload = () => {
    (window as any).app = new App(); // make app accessible in console for debug/testing, just type "app" in the browser console
};